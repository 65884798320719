import { lazy } from 'react';
import AuthGuard from 'utils/route-guard/AuthGuard';

// project imports

import Loadable from 'ui-component/Loadable';
import MainLayout from 'layout/MainLayout';

// Department
const PaDashboard = Loadable(lazy(() => import('views/paDashboard/index')));
const roles = ['ROLE_ADMIN', 'ROLE_COLLEGE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_COLLEGE_SUB_ADMIN'];

const PaRoutes = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),

    children: [
        {
            path: '/pa/dashboard',
            element: <PaDashboard />
        }
    ]
};

export default PaRoutes;
