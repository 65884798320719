import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const roles = [
    'ROLE_STUDENT',
    'ROLE_STUDENT_ADMISSION',
    'ROLE_ADMIN',
    'ROLE_COLLEGE_SUB_ADMIN',
    'ROLE_ADMIN',
    'ROLE_COLLEGE_ADMIN',
    'ROLE_HIRER'
];

const Companies = Loadable(lazy(() => import('views/application/placement/companies/index')));
const CompaniesEdit = Loadable(lazy(() => import('views/application/placement/companies/Add')));
const CreateJobs = Loadable(lazy(() => import('views/application/placement/jobs/index')));
const EditJobsOpenings = Loadable(lazy(() => import('views/application/placement/jobs/EditJobOpening')));
const CreateJobsOpenings = Loadable(lazy(() => import('views/application/placement/jobs/Add')));
const PackageCategoryList = Loadable(lazy(() => import('views/application/placement/packagecategory/index')));
const SkillsList = Loadable(lazy(() => import('views/application/placement/skills/index')));
const JobsOpeningsDetails = Loadable(lazy(() => import('views/application/placement/jobs/JobOpeningsDetails')));
const CompanyDashboard = Loadable(lazy(() => import('views/companyregistration/companydashboard/CompanyDashboard')));
const JobTracker = Loadable(lazy(() => import('views/application/placement/jobtracker/jobtrackertab/JobTrackerTab')));
const StudentListByJobId = Loadable(lazy(() => import('views/application/placement/jobtracker/jobtrackertab/StudentTab')));
const CompanyDetails = Loadable(lazy(() => import('views/companyregistration/companydashboard/companydetails/index')));
const CompanyJob = Loadable(lazy(() => import('views/companyregistration/companydashboard/createjob/index')));
// const CompanyJobTracker = Loadable(lazy(() => import('views/companyregistration/companydashboard/jobtracker/jobtrackertab/JobTrackerTab')));
const CompanyJobTracker = Loadable(lazy(() => import('views/application/placement/jobtracker/jobtrackertab/JobTrackerTab')));
const CompanyInterview = Loadable(lazy(() => import('views/application/placement/jobtracker/ongoinginterviewprocess/InterviewRoundTabs')));
const StudentJobReport = Loadable(lazy(() => import('views/application/placement/report/StudentReport')));
const PlacementQualification = Loadable(lazy(() => import('views/application/placement/qualification/index')));
const Dashboard = Loadable(lazy(() => import('views/application/placement/dashboard/Dashboard')));
const StudentList = Loadable(lazy(() => import('views/application/placement/dashboard/StudentLIst')));
const CompaniesJobEdit = Loadable(lazy(() => import('views/companyregistration/companydashboard/createjob/Edit')));
const CompaniesParticipate = Loadable(lazy(() => import('views/application/placement/dashboard/companiesdashboard/ParticipatingCompany')));
const JobListByCompany = Loadable(lazy(() => import('views/application/placement/dashboard/companiesdashboard/JobListByCompany')));
const StudentListByJobOpenings = Loadable(lazy(() => import('views/application/placement/dashboard/companiesdashboard/StudentListByJob')));
const PlaceMentWeek = Loadable(lazy(() => import('views/application/placement/placementweek/index')));
const CompanyJobList = Loadable(lazy(() => import('views/application/placement/joblistcreatedbycompany/JobList')));
const JobListCompany = Loadable(lazy(() => import('views/application/placement/joblistcreatedbycompany/JobOppeningApproveByAdmin')));
const JobApprovedByAdmin = Loadable(lazy(() => import('views/application/placement/joblistcreatedbycompany/StudentListByFilter')));
const InstituuteInformationPage = Loadable(lazy(() => import('views/application/placement/universityinfo/index')));
const OutReachCompany = Loadable(lazy(() => import('views/application/placement/out-reach-company/index')));
const JobStudentsLists = Loadable(lazy(() => import('views/companyregistration/companydashboard/report/StudentListByJob')));
const CandidatesLists = Loadable(lazy(() => import('views/companyregistration/companydashboard/report/TotalCandidatesList')));
const HiredCandidatesList = Loadable(lazy(() => import('views/companyregistration/companydashboard/hiredcandidates/HiredCandidatesList')));
const DesignationWiseCandidatesList = Loadable(
    lazy(() => import('views/application/placement/dashboard/appecchart/DesignationWiseStudentReport'))
);
const PackageWiseCandidatesList = Loadable(lazy(() => import('views/application/placement/dashboard/appecchart/PackageWiseStudentReport')));
const CourseWiseCandidatesList = Loadable(lazy(() => import('views/application/placement/dashboard/appecchart/CourseWiseStudentReport')));
const CompanyWiseCandidatesList = Loadable(lazy(() => import('views/application/placement/dashboard/appecchart/CompanyWiseStudentReport')));
const CandidatesProfile = Loadable(lazy(() => import('views/application/placement/CandidatesProfile')));
const CreateInternship = Loadable(lazy(() => import('views/application/placement/jobs/index')));
const StudentPlacement = Loadable(lazy(() => import('views/student/student-dashboard/placement/placementtabs/PlacementTabs')));

const PlacementRouts = {
    path: '/',
    element: (
        <AuthGuard roles={roles}>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/placement/dashboard',
            element: <Dashboard />
        },
        {
            path: '/student/list/:type',
            element: <StudentList />
        },
        {
            path: '/comapnies/participate',
            element: <CompaniesParticipate />
        },
        {
            path: '/job/list/by/company/:id',
            element: <JobListByCompany />
        },
        {
            path: '/student/list/by/job/:id/:cid',
            element: <StudentListByJobOpenings />
        },
        {
            path: '/companies/list',
            element: <Companies />
        },
        {
            path: '/companies/edit/:id',
            element: <CompaniesEdit />
        },
        {
            path: '/company/job/edit/:jobId',
            element: <CompaniesJobEdit />
        },
        {
            path: '/create/jobs/openings',
            element: <CreateJobs />
        },
        {
            path: '/create/jobs/openings/:sessionId/:companyId',
            element: <CreateJobsOpenings />
        },
        {
            path: '/edit/jobs/openings/:jobId',
            element: <EditJobsOpenings />
        },
        {
            path: '/placement/qualification',
            element: <PlacementQualification />
        },
        {
            path: '/jobs/openings/details/:sessionId/:companyId/:tab',
            element: <JobsOpeningsDetails />
        },
        {
            path: '/package/category/list',
            element: <PackageCategoryList />
        },
        {
            path: '/skills/list',
            element: <SkillsList />
        },
        {
            path: '/job/tracker',
            element: <JobTracker />
        },
        {
            path: '/student/list/:jobId/:sessionId',
            element: <StudentListByJobId />
        },
        {
            path: '/student/report',
            element: <StudentJobReport />
        },
        {
            path: '/company/dashboard',
            element: <CompanyDashboard />
        },
        {
            path: '/company/details',
            element: <CompanyDetails />
        },
        {
            path: '/create/jobs',
            element: <CompanyJob />
        },
        {
            path: '/company/job/tracker',
            element: <CompanyJobTracker />
        },
        {
            path: '/company/interview/:jobId/:index',
            element: <CompanyInterview />
        },
        {
            path: '/placementweeks',
            element: <PlaceMentWeek />
        },
        {
            path: '/comapny/job/list',
            element: <CompanyJobList />
        },
        {
            path: '/job/list/:cId/:sId/:jId',
            element: <JobListCompany />
        },
        {
            path: '/job/approve/by/admin/:jobId/:sId',
            element: <JobApprovedByAdmin />
        },
        {
            path: '/institute/information/page',
            element: <InstituuteInformationPage />
        },
        {
            path: '/outreach/companies',
            element: <OutReachCompany />
        },
        {
            path: '/job/students/list/:jobId',
            element: <JobStudentsLists />
        },
        {
            path: '/candidates/list',
            element: <CandidatesLists />
        },
        {
            path: '/hired/candidates/list',
            element: <HiredCandidatesList />
        },
        {
            path: '/designation/wise/candidates/report/:type',
            element: <DesignationWiseCandidatesList />
        },
        {
            path: '/package/wise/candidates/report/:type',
            element: <PackageWiseCandidatesList />
        },
        {
            path: '/course/wise/candidates/report/:type',
            element: <CourseWiseCandidatesList />
        },
        {
            path: '/company/wise/candidates/report/:type',
            element: <CompanyWiseCandidatesList />
        },
        {
            path: '/candidates/profile/:studentId',
            element: <CandidatesProfile />
        },
        {
            path: '/create/internship',
            element: <CreateInternship />
        },
        {
            path: '/placement',
            element: <StudentPlacement />
        }
    ]
};
export default PlacementRouts;
